/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import nom_logo from "../assets/nom_logo.png";

import "../scss/_navbar.scss";

const LogoBanner = () => {
    return<div className="logo_container">
        <Link to='/'><img className="logo" src={nom_logo} alt="" /></Link>
        <Link to='/'><img className="nom" src={logo} alt="" /></Link>
    </div>;
};

export default LogoBanner;
