import React from 'react';
import '../scss/_clientele.scss';





const clienteles = [
    {
        name: 'Entreprises',
        advantages: 'Notre logiciel aide les entreprises à gérer efficacement leurs opérations, à optimiser leur chaîne d\'approvisionnement et à améliorer leur rentabilité. Les outils de gestion de stock, de vente et de trésorerie permettent aux entreprises de se concentrer sur leur croissance et leur développement.',
        id: 'entreprises',
        image: 'https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
  
    {
        name: 'Institutions Éducatives',
        advantages: 'Les écoles et universités utilisent notre logiciel pour gérer leurs ressources, suivre les paiements des étudiants et optimiser la gestion administrative. Les fonctionnalités de gestion des clients et des finances offrent une transparence accrue et une efficacité administrative améliorée.',
       
        id: 'institutions_educatives',
        image: 'https://images.pexels.com/photos/2675061/pexels-photo-2675061.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        name: 'Hôpitaux',
        advantages: 'Les hôpitaux et cliniques bénéficient de notre logiciel pour gérer leurs stocks de médicaments, suivre les paiements des patients et améliorer la gestion financière. La fonctionnalité de suivi en temps réel assure une gestion efficace des ressources médicales.',
       
        id: 'hopitaux',
        image: 'https://images.pexels.com/photos/263402/pexels-photo-263402.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        name: 'Commerces de Détail',
        advantages: 'Les commerces de détail utilisent notre logiciel pour gérer leurs ventes, suivre les stocks et intégrer les paiements mobiles. Les outils de gestion des clients et des fournisseurs aident à améliorer la satisfaction client et à optimiser les opérations commerciales.',
       
        id: 'commerces_detail',
        image: 'https://images.pexels.com/photos/1670045/pexels-photo-1670045.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
        name: 'Industries',
        advantages: 'Les industries profitent de notre solution pour gérer leurs chaînes d\'approvisionnement, optimiser la production et améliorer la gestion financière. Les fonctionnalités avancées de notre logiciel aident à maintenir une production efficace et à réduire les coûts opérationnels.',

        id: 'industries',
        image: 'https://images.pexels.com/photos/1267338/pexels-photo-1267338.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
];

const Clientele = () => {
  return (
    <div className="clientele-section">
      <h2>Nos Principales Clientèles</h2>
      <div className="clientele-container">
        {clienteles.map((client, index) => (
          <div key={index} className="clientele-card">
            <div className="icon"><img src={client.image} alt="" /></div>
            <div className="content">
              <h3>{client.name}</h3>
              <p>{client.advantages}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clientele;
