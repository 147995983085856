import React, { useState } from 'react';
// import Switch from 'react-switch';
import { FaCheck } from 'react-icons/fa'; 
import '../scss/_pricing.scss';

const basicprice=9.5;
const croissanceprice=19;
const proprice=39;
const supplementprice=50;

const plans = [
  {
    name: 'Basic',
    description: 'Indépendants et solo preneurs PME avec des aspirations de croissance',
    features: [
      '1 Point de vente',
      'Gestion des commandes',
      'Gestion de stock',
      'Gestion des ventes',
      'Gestion des fournisseurs',
      'Gestions des paiements',
      'Gestion des dépenses',
      'Gestion de la sécurité et des accès au système selon le rôle de l’utilisateur',
      'Gestions des utilisateurs',
    ],
    users: '3 utilisateurs',
    prices: {
      Semestre: ((basicprice*2) - (12 / 100)).toFixed(1),
      Trimestre: basicprice,
      Année: ((basicprice*3)- (17 / 100)).toFixed(1),
    },
  },
  {
    name: 'Croissance',
    description: 'PME avec des aspirations de croissance',
    features: [
      'Basic +',
      '3 points de vente',
      'Gestions des clients',
      'Gestions des commandes',
      'Gestions de livraisons',
      'Intégration des paiements MoMo (Mpesa, Orange Money, Airtel Money et AfriMoney)',
    ],
    users: '9 utilisateurs',
    prices: {
      Semestre: ((croissanceprice*2) - (12 / 100)).toFixed(1),
      Trimestre: croissanceprice,
      Année: ((croissanceprice*3)- (17 / 100)).toFixed(1),
    },
  },
  {
    name: 'Pro',
    description: 'Entreprises en croissance ayant des besoins en stocks',
    features: [
      'Croissance +',
      '5 points de vente',
      '20 utilisateurs',
      'd’options CRM',
      'Gestion des mouvements comptables de caisse et des opérations diverses',
      'Envoi de facture via Telegram, email',
      'MoMo et Mobile Banking (EquityBCDC)',
      'Reporting avancés',
      'Rapport financiers',
      'Cartes Cadeaux, fidélisation des clients',
    ],
    users: '20 utilisateurs',
    prices: {
      Semestre: ((proprice*2) - (12 / 100)).toFixed(1),
      Trimestre: proprice,
      Année: ((proprice*3) - (17 / 100)).toFixed(1),
    },
  },
  {
    name: 'Entreprise',
    description: 'PMEs plus établies et Grandes Entreprises',
    features: [
      'Pro +',
      '20 utilisateurs',
      'Comptabilités',
      'Ouverts aux personnalisations',
      'Intégration de API WhatsApp',
    ],
    users: 'Contactez-nous',
    prices: {
      Semestre: 'Nous Contacter',
      Trimestre: 'Nous Contacter',
      Année: 'Nous Contacter',
    },
  },
  {
    name: 'Suppléments',
    description: 'PME avec des aspirations de croissance',
    features: [
      'Intégration de API WhatsApp',
      'Envoi des factures par WhatsApp',
      'Messages de diffusions SMS, WhatsApp, Telegram',
    ],
    users: 'Nous contacter',
    prices: {
      Semestre: ((supplementprice*2) - (12 / 100)).toFixed(1),
      Trimestre: supplementprice,
      Année: ((supplementprice*3) - (17 / 100)).toFixed(1),
    },

  },
];




const Pricing = () => {
  const [period, setPeriod] = useState('Trimestre');

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  return (
    <div className="pricing-section">
      <h2>Nos Plans</h2>
      <span>ToonaShop est facturé Trimestriellement, Semestriellement et annuellement </span>
      
      <div className="period-selector">
        <div className="switcher">
          <div 
            className={`switcher-option ${period === 'Trimestre' ? 'active' : ''}`} 
            onClick={() => handlePeriodChange('Trimestre')}
          >
            Trimestre
          </div>
          <div 
            className={`switcher-option ${period === 'Semestre' ? 'active' : ''}`} 
            onClick={() => handlePeriodChange('Semestre')}
          >
            Semestre
          </div>
          <div 
            className={`switcher-option ${period === 'Année' ? 'active' : ''}`} 
            onClick={() => handlePeriodChange('Année')}
          >
            Annuel
          </div>
        </div>
      </div>

      <div className="plans">
        {plans.map((plan, index) => (
          <div key={index} className="plan">
            <div className="main_header">
              <div className="header">
                <h3>
                  {plan.name} 
                  {/* {plan.name === 'Croissance' && (
                    <span className="badge">Recommandé</span> // Adding the badge for "Croissance" plan
                  )} */}
                </h3>
                <p className="price">
                  {plan.name === 'Entreprise' 
                    ? 'Nous contacter' 
                    // : `$${plan.prices[period]} ${period === 'Trimestre' ?  `/${period}`: ''}`
                    : `$${plan.prices[period]} ${period === 'Trimestre' ?  '': ''}`
                  }
                </p>
              </div>
              <div className="future">
                <ul>
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                <p className="users">{plan.users}</p>
              </div>
            </div>
            <button className="select-plan">Sélectionner</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;