import React from 'react';
import '../scss/_blogpage.scss';
import macbook from '../assets/macbook.jpg';

const recentBlogs = [
  {
    title: 'Comment améliorer la gestion financière de votre entreprise',
    description: 'Découvrez des astuces pour optimiser la gestion financière de votre entreprise et maximiser vos profits.',
    date: '2024-07-10',
    image: 'https://images.pexels.com/photos/210607/pexels-photo-210607.jpeg?auto=compress&cs=tinysrgb&w=600',
    link: '/blog/gestion-financiere'
  },
  {
    title: 'L\'importance de la digitalisation pour les PME',
    description: 'Explorez les avantages de la digitalisation pour les petites et moyennes entreprises.',
    date: '2024-07-05',
    image: 'https://images.pexels.com/photos/4158/apple-iphone-smartphone-desk.jpg?auto=compress&cs=tinysrgb&w=600',
    link: '/blog/digitalisation-pme'
  },
  {
    title: 'Comment améliorer la gestion financière de votre entreprise',
    description: 'Découvrez des astuces pour optimiser la gestion financière de votre entreprise et maximiser vos profits.',
    date: '2024-07-10',
    image: 'https://images.pexels.com/photos/210607/pexels-photo-210607.jpeg?auto=compress&cs=tinysrgb&w=600',
    link: '/blog/gestion-financiere'
  },
 
];

const olderBlogs = [
  {
    title: 'Comment automatiser vos processus d\'entreprise',
    description: 'Un guide sur l\'automatisation des processus pour gagner du temps et augmenter l\'efficacité.',
    date: '2024-06-15',
    image: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=600',
    link: '/blog/automatisation-processus'
  },
  {
    title: 'Les meilleurs outils de gestion pour les PME',
    description: 'Une liste des meilleurs outils de gestion disponibles pour les petites et moyennes entreprises.',
    date: '2024-05-25',
    image: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=600',
    link: '/blog/outils-gestion'
  }
];

const BlogPage = () => {
  return (
    <div className="blog-page">
      <h1>Découvrez nos derniers articles</h1>
      <div className="recent-blogs">
        <h2>Articles Récents</h2>
        <div className="blogs-container">
          {recentBlogs.map((blog, index) => (
            <div key={index} className="blog-card">
              <img src={blog.image} alt={blog.title} />
              <div className="blog-content">
                <h3>{blog.title}</h3>
                <p>{blog.description}</p>
                <p className="blog-date">{new Date(blog.date).toLocaleDateString()}</p>
                <a href={blog.link} className="read-more-btn">Lire Plus</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="older-blogs">
        <h2>Articles Plus Anciens</h2>
        <div className="blogs-container">
          {olderBlogs.map((blog, index) => (
            <div key={index} className="blog-card">
              <img src={blog.image} alt={blog.title} />
              <div className="blog-content">
                <h3>{blog.title}</h3>
                <p>{blog.description}</p>
                <p className="blog-date">{new Date(blog.date).toLocaleDateString()}</p>
                <a href={blog.link} className="read-more-btn">Lire Plus</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
