import React from 'react';
import { Link } from 'react-router-dom';
import macbook from '../assets/macbook.jpg'
import '../scss/_avantages.scss';

const avantages = [
    {
        title: 'Gestion Financière Ameliorée',
        description: 'Optimisez la gestion de vos finances avec des outils avancés pour mieux gérer vos flux de trésorerie.',
        image: 'https://images.pexels.com/photos/2988232/pexels-photo-2988232.jpeg?auto=compress&cs=tinysrgb&w=600',
        solutionLink: '/solutions#gestion-financiere',
    },
    {
        title: 'Formation et Soutien',
        description: 'Accédez à des modules de formation en ligne pour améliorer vos compétences en gestion.',
        image: 'https://images.pexels.com/photos/5083397/pexels-photo-5083397.jpeg?auto=compress&cs=tinysrgb&w=600',
        solutionLink: '/solutions#formation-soutien',
    },
    {
        title: 'Transparence et Crédibilité',
        description: 'Générez des rapports financiers précis pour augmenter votre transparence et votre crédibilité.',
        image: 'https://images.pexels.com/photos/669612/pexels-photo-669612.jpeg?auto=compress&cs=tinysrgb&w=600',
        solutionLink: '/solutions#transparence-credibilite',
    },
    {
        title: 'Suivi en Temps Réel',
        description: 'Profitez d’un suivi en temps réel de vos performances avec des données actualisées.',
        image: 'https://images.pexels.com/photos/265087/pexels-photo-265087.jpeg?auto=compress&cs=tinysrgb&w=600',
        solutionLink: '/solutions#suivi-temps-reel',
    },
    {
        title: 'Automatisation des Processus',
        description: 'Automatisez les processus administratifs pour réduire la charge bureaucratique et vous concentrer sur la croissance.',
        image: 'https://th.bing.com/th/id/OIP.fr6gqpNBpitaad5Y0gf1NwHaHa?rs=1&pid=ImgDetMain',
        solutionLink: '/solutions#automatisation-processus',
    },
    {
        title: 'Renforcement de la Relation de Confiance',
        description: 'Facilitez une communication claire et fournissez des preuves de bonne gestion pour renforcer la confiance.',
        image: 'https://images.pexels.com/photos/3184294/pexels-photo-3184294.jpeg?auto=compress&cs=tinysrgb&w=600',
        solutionLink: '/solutions#renforcement-confiance',
    },
];


const Avantages = () => {
  return (
    <div className="avantages-section">
      <h2>Toona<span>Shop </span>vous apporte un grand nombre d'avantages</h2>
      <div className="avantages-container">
        {avantages.map((avantage, index) => (
          <div key={index} className="avantage">
            <img src={avantage.image} alt={avantage.title} />
            <div className="content">
              <h3>{avantage.title}</h3>
              <p>{avantage.description}</p>
              <a href="#"><Link to={avantage.solutionLink} className="solution-link">En savoir plus</Link></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Avantages;
