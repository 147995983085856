/* eslint-disable jsx-a11y/anchor-is-valid */

import '../scss/_Footer.scss';

import { FaFacebook,FaLinkedin } from 'react-icons/fa';

import LogoBanner from './LogoBanner';
import { useState } from 'react';
import { Link } from 'react-router-dom';


const Footer = (props) => {

    const [isopen, setisopen]=useState(false);

    return <footer>

        <div className="top">
            <div className="left">
                <LogoBanner />
                
                <p>Logiciel de Gestion de Stock</p>
         
                <div className='social_media' id='social_media'>
                    <a href="https://web.facebook.com/calculussystem/"><FaFacebook  className='icon' /></a>
                    <a href="https://www.linkedin.com/company/calculus-system-sarl/"><FaLinkedin className='icon' /></a>
                </div>
            </div>
            <div className="right">
            <div className="links_ressources">
                    <a><Link className='span' to='/solutions'>Solutions</Link></a>
                    <li>
                    <a href='' onClick={() => setisopen(false)}><Link to='/solutions#formation-soutien'>Formation et Soutien</Link></a>
                    </li>
                    <li>
                    <a href="#" onClick={() => setisopen(false)}><Link to='/solutions#gestion-financiere'>Rappports financiers</Link></a>
                    </li>
                    <li>
                    <a href="#" onClick={() => setisopen(false)}><Link to='/solutions#suivi-temps-reel'>Suivi temps Réel</Link></a>
                    </li>
                    <li>
                    <a href="#" onClick={() => setisopen(false)}><Link to='/solutions#automatisation-processus'>Automatisation Processus</Link></a>
                    </li>
                    <li>
                    <a href="#" onClick={() => setisopen(false)}><Link to='/solutions#renforcement-confiance'>Relation de Confiance</Link></a>
                    </li>
                </div>
                <div className="links_ressources">
                    <a><Link  className='span' to='/products'>PRODUIT</Link></a>
                    <li>
                    <a href='' onClick={() => setisopen(false)}><Link to='/products#retail'>Retail</Link></a>
                    </li>
                    <li>
                    <a href='' onClick={() => setisopen(false)}><Link to='/products#pharmacie_medical'>Pharmacie et Medical</Link></a>
                    </li>
                    <li>
                    <a href='' onClick={() => setisopen(false)}><Link to='/products#restaurant'>Restaurant</Link></a>
                    </li>
                    <li>
                    <a href='' onClick={() => setisopen(false)}><Link to='/products#industrie'>Industrie</Link></a>
                    </li>
                </div>
                <div className="links">
                    <a><Link className='span' to='clientele'>CLIENTELE</Link></a>
                    <a><Link  className='span' to='/pricing'>TARIFS</Link></a>
                </div>
                <div className="links_ressources">
                    <a>RESSOURCES</a>
                    <li>
                        <a href=""><Link to='/blog'>Blog</Link></a>
                    </li>
                    <li>
                        <a href=""><Link to='/faq'>FAQS</Link></a>
                    </li>
                    <li>
                        <a href=""><Link to='/aboutus'>A propos de nous</Link></a>
                    </li>
                </div>
            </div>
        </div>
        <div className="bottom">
            <span>&copy; Copyright 2024 - <a href="https://calculus-system.cd/">Calculus System</a></span>
        </div>

    </footer>
}

export default Footer;