import React, { useState } from 'react';
import Partenaire from './partenaire';
import '../scss/_faqs.scss'; // Assurez-vous d'avoir les styles nécessaires

const FAQs = () => {
  const questions = [
    {
      question: "Comment puis-je utiliser cette application ?",
      answer: "Pour utiliser cette application, vous devez vous inscrire et créer un compte. Ensuite, vous pouvez accéder à toutes les fonctionnalités disponibles.Ensuite, vous pouvez accéder à toutes les fonctionnalités disponibles.Ensuite, vous pouvez accéder à toutes les fonctionnalités disponibles.Ensuite, vous pouvez accéder à toutes les fonctionnalités disponibles."
    },
    {
      question: "Quelle est la politique de confidentialité ?",
      answer: "Notre politique de confidentialité assure que vos données sont protégées et ne seront pas partagées avec des tiers sans votre consentement."
    },
    {
      question: "Comment puis-je réinitialiser mon mot de passe ?",
      answer: "Vous pouvez réinitialiser votre mot de passe en cliquant sur 'Mot de passe oublié' sur la page de connexion et en suivant les instructions."
    },

  ];

  const [expanded, setExpanded] = useState(null);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return <>
  <h2>Les Questions Fréquemment posées</h2>
  <div className="faqs">
      {questions.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            {item.question}
          </div>
          {expanded === index && (
            <div className="faq-answer">
              {item.answer}
            </div>
          )}
        </div>
      ))}
      <div className="ellipse_container">
        <div className="ellipse pink"></div>
        <div className="ellipse orange"></div>
      </div>
    </div>
    <Partenaire content='Essayez ToonaShop Gratuitement' isPartenaire={false} /></>

};

export default FAQs;
