import React, { useEffect } from 'react';
import '../scss/_solutions.scss';
import { FaMoneyCheckAlt, FaChalkboardTeacher, FaRegFileAlt, FaClock, FaCog, FaHandshake } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const solutions = [
    {
        id: 1,
        keyid : 'gestion-financiere',
        title: 'Amélioration de la gestion financière',
        description: 'ToonaSHOP peut offrir des outils de gestion financière avancés, aidant les MPMEs à mieux gérer leurs flux de trésorerie, à planifier leurs finances et à démontrer leur capacité de remboursement aux IMF',
        icon: <FaMoneyCheckAlt />
    },
    {
        id: 2,
        keyid : 'formation-soutien',
        title: 'Formation et soutien',
        description: 'ToonaSHOP peut intégrer des modules de formation en ligne pour améliorer les compétences en gestion des utilisateurs, aidant ainsi les MPMEs à mieux utiliser les financements et à croître de manière efficace.',
        icon: <FaChalkboardTeacher />
    },
    {
        id: 3,
        keyid:'transparence-credibilite',
        title: 'Transparence et crédibilité',
        description: 'En utilisant ToonaSHOP, les MPMEs peuvent générer des rapports financiers précis et réguliers, augmentant leur transparence et leur crédibilité auprès des IMF.',
        icon: <FaRegFileAlt />
    },
    {
        id: 4,
        keyid:'suivi-temps-reel',
        title: 'Suivi en temps réel',
        description: 'ToonaSHOP permet un suivi en temps réel des performances de l\'entreprise, fournissant des données actualisées qui peuvent être utilisées pour rassurer les IMF sur la santé financière de l\'entreprise.',
        icon: <FaClock />
    },
    {
        id: 5,
        keyid:'automatisation-processus',
        title: 'Automatisation des processus',
        description: 'ToonaSHOP permet d\'automatiser de nombreux processus administratifs et réglementaires, réduisant ainsi la charge bureaucratique et permettant aux MPMEs de se concentrer sur leur croissance.',
        icon: <FaCog />
    },
    {
        id: 6,
        keyid: 'renforcement-confiance',
        title: 'Renforcement de la relation de confiance',
        description: 'En facilitant une communication claire et en fournissant des preuves de bonne gestion, ToonaSHOP peut aider à réduire la méfiance entre les MPMEs et les IMF.',
        icon: <FaHandshake />
    }
];

const SolutionPage = () => {

    const location=useLocation();

    useEffect(()=>{

      if(location.hash){
      const element=document.getElementById(location.hash.replace('#',''));
      if(element){
          const yOffset = -100; 
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
      }
      }
  }, [location])
  return (
    <div className="solutions-section">
      <h2>Nos Solutions</h2>
      <div className="solutions-container">
        {solutions.map((solution) => (
          <div key={solution.id} className="solution-card" id={solution.keyid}>
            <div className="icon">
              {solution.icon}
            </div>
            <div className="content">
              <h3>{solution.title}</h3>
              <p>{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolutionPage;
