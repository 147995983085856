/* eslint-disable jsx-a11y/anchor-is-valid */

import '../scss/_partenaire.scss';

const Partenaire = (props) => {

    const handleClick = () => {

        window.open('https://play.google.com/store/apps/details?id=com.toonashop.toonashopv2',
            '_blank', 'noopener,noreferrer'
        );
    }

    return <div className="partenaire">
                <span> {props.content}</span>
                {!props.isPartenaire && <button type='button' onClick={handleClick}>Essai Gratuit</button> }
            </div>
}

export default Partenaire;