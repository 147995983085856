import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import '../scss/_visitorcounter.scss';

const VisitorCounter = () => {
    const [visitorCount, setVisitorCount] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        // Récupérer le nombre de visites depuis localStorage
        const storedCount = localStorage.getItem('visitorCount');
        const count = storedCount ? parseInt(storedCount) : 0;

        // Mettre à jour le compteur de visites
        setVisitorCount(count + 1);

        // Enregistrer le nouveau compteur dans localStorage
        localStorage.setItem('visitorCount', count + 1);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setHasAnimated(true);
                }
            },
            {
                threshold: 0.5, // Le seuil de 50% du composant visible avant déclenchement
            }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, [hasAnimated]);

    return (
        <div ref={counterRef} className="visitor-counter">
            <div className="count-list">
                <h1>VISITEURS</h1>
                {hasAnimated && (
                    <CountUp
                        className='animate-number'
                        start={0}
                        end={visitorCount}
                        duration={15.5}
                        separator=","
                    />
                )}
            </div>
            <div className="count-list">
                <h1>UTILISATEURS</h1>
                {hasAnimated && (
                    <CountUp
                        className='animate-number'
                        start={0}
                        end={visitorCount}
                        duration={15.5}
                        separator=","
                    />)}
            </div>
            <div className="count-list">
                <h1>CLIENTS</h1>
                {hasAnimated && (
                    <CountUp
                        className='animate-number'
                        start={0}
                        end={visitorCount}
                        duration={15.5}
                        separator=","
                    />
                )}
            </div>
            
        </div>
    );
};

export default VisitorCounter;
