import { Navigate, Outlet, RouterProvider, createBrowserRouter} from "react-router-dom";

import NavBar from './components/Navbar';


import './scss/_base.scss';

import Footer from './components/Footer';

import { useEffect, useState } from 'react';
import scrollreveal from 'scrollreveal';
import HomePage from './components/HomePage';
import FAQs from "./components/Faqs";
import AboutUs from "./components/AboutUs";
import ProductPage from "./Pages/ProductPage";
import Pricing from "./components/Pricing";
import SolutionPage from "./Pages/SolutionPage";
import Clientele from "./Pages/ClientelePage";
import BlogPage from "./Pages/BlogPage";
import ShopPage from "./Pages/ShopPage";
import Modal from "./components/Modal";
import ModalContent from "./components/ModalContent";
import TryForFree from "./components/Tryforfree";
import Login from "./Pages/LoginPage";




function App() {

  const [theme, settheme]= useState('light');
  const [showTryForFreeModal, setShowTryForFreeModal] = useState(false);
  const [showWebinarModal, setShowWebinarModal] = useState(false);

  const changetheme = () =>{

    if (theme === 'dark'){
      settheme('light');
    }else{
      settheme('dark');
    }
    

  }

  
  

  useEffect(() =>{
    const registeranimation=() =>{
        const sr=scrollreveal({
          origin: 'bottom',
          distance: '80px',
          duration:2000,
          reset:false,
        });
        sr.reveal(
          `nav, .home,.partenaire,.avantages-container,.visitor-counter,.fonctionnalities,.avisclients,footer`,
          {interval : 500}
        )
    }

    registeranimation();
    const timer = setTimeout(() => setShowTryForFreeModal(true), 60000); // Show "Try for Free" modal after 1 minute
    return () => clearTimeout(timer);
  }, []);



  const Layout = () => {
    return(
      <>
        <NavBar changetheme={changetheme} currenttheme={theme} setShowWebinarModal={setShowWebinarModal} />,
        <Outlet />,
        <Modal isOpen={showTryForFreeModal} onClose={() => setShowTryForFreeModal(false)}>
          <TryForFree />
        </Modal>,
        <Modal isOpen={showWebinarModal} onClose={() => setShowWebinarModal(false)}>
          <ModalContent setShowWebinarModal={setShowWebinarModal} />
        </Modal>,
        <Footer />,
      </>
    );
  }
  const routes = createBrowserRouter([
    {
      path:'/', 
      element: <Layout />,
      children : [
        {
          path:'/',
          element : <HomePage />
        },
        {
          path:'/solutions',
          element : <SolutionPage />,
        },
        {
          path:'/faq',
          element : <FAQs />
         
        },
        {
          path:'/aboutus',
          element : <AboutUs />
        },
        {
          path:'/blog',
          element : <BlogPage />
        },
        {
          path:'/products',
          element : <ProductPage />
        },
        {
          path:'/pricing',
          element : <Pricing />
        },
        {
          path:'/clientele',
          element : <Clientele />
        },
        {
          path:'/shoppage',
          element : <ShopPage />
        },
        {
          path:'*',
          element: <Navigate to="/" replace />
        }
      ]
    },
    {
      path:'/login',
      element : <Login />
    }
  ])
  return (
      <div className="app-container" data-theme={theme}>
        <RouterProvider router={routes} />
      </div>
    
  );
}

export default App;
