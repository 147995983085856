import '../scss/_tryforfree.scss';

const TryForFree =(props) =>{


    return <>
    <div className="content">
        <h2>Essayez ToonaShop gratuitement pendant 30 jours</h2>
        <p>Experience notre Servie avec toutes ses fonctionnalités</p>
        <button onClick={() => props.setShowTryForFreeModal(false)}>Essai Gratuit</button>
    </div>

    </>
}


export default TryForFree;