import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../scss/_AvisClients.scss';

const AvisClients = () => {
  const avis = [
    {
      image: 'https://images.pexels.com/photos/681118/pexels-photo-681118.jpeg?auto=compress&cs=tinysrgb&w=600',
      texte: "J'aime bien utiliser cette application pour la gestion de stock de mon entreprise",
      nom: "Jean - Directeur Commercial",
      entreprise: "Chez Elite"
    },
    {
      image: 'https://images.pexels.com/photos/1095814/pexels-photo-1095814.jpeg?auto=compress&cs=tinysrgb&w=600',
      texte: "Une application incroyable qui a simplifié notre gestion des stocks",
      nom: "Marie - Responsable Logistique",
      entreprise: "Chez ABC"
    },
    {
      image: 'https://images.pexels.com/photos/35016/soap-bubble-colorful-ball-soapy-water.jpg?auto=compress&cs=tinysrgb&w=600',
      texte: "Très utile et intuitive, je recommande fortement",
      nom: "Pierre - Gérant",
      entreprise: "Chez XYZ"
    }
    // Ajoutez d'autres avis ici
  ];

  const [index, setIndex] = useState(0);

  const handlePrevClick = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? avis.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setIndex((prevIndex) => (prevIndex === avis.length - 1 ? 0 : prevIndex + 1));
  };

  // Ajouter un défilement automatique
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === avis.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Change d'avis toutes les 5 secondes

    return () => {
      clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage du composant
    };
  }, [avis.length]);

  return (
    <div className="avisclients">
      <button onClick={handlePrevClick}><FaArrowLeft /></button>
      <div className="avis_container">
        <div className="image_container">
          <img src={avis[index].image} alt={avis[index].nom} />
        </div>
        <div className="ellipse_container">
          <div className="ellipse orange"></div>
        </div>
        <div className="texte_container">
          <p>{avis[index].texte}</p>
          <div className="identite">
            <p>{avis[index].nom}</p>
            <p>{avis[index].entreprise}</p>
          </div>
        </div>
      </div>
      <button onClick={handleNextClick}><FaArrowRight /></button>
    </div>
  );
};

export default AvisClients;
