import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const WebinarModal = ({ show, handleClose }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Webinar Invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Webinar Title</h4>
            <p>Date: 2023-09-01</p>
            <p>Description: Learn about our product features.</p>
            <p>Speaker: John Doe</p>
            <p>Contact: john.doe@example.com, 123-456-7890</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={() => alert('Registered for Webinar')}>
                Register
            </Button>
        </Modal.Footer>
    </Modal>
);

export default WebinarModal;
