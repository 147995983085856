import pc_phone from '../assets/toonnaapp_2.svg';
import phone from '../assets/tonnaapp.svg';

import '../scss/_home.scss';
import useImageSlider from '../Hooks/useImageSilder';


const Home = () => {


    const images=[phone, pc_phone];
    const image=useImageSlider(images, 3000);

    const handleClick = () => {

        window.open('https://play.google.com/store/apps/details?id=com.toonashop.toonashopv2',
            '_blank', 'noopener,noreferrer'
        );
    }

    

    return <div className="home">
        <div className="container">
        <div className="left">
            <h2>
                Optimisez Votre Gestion de Stock avec ToonaShop
            </h2>
            <p>
                Une solution complète pour gérer vos entrepots,sites de ventes, 
                fournisseurs, articles, utilisateurs et clients en toute simplicité
            </p>
            <button type='button' onClick={handleClick}>
                Essai gratuit
            </button>
        </div>
        <div className="right">
            <div className="image_container_two">
                <img src={image} alt="" />
            </div>
            <div className="ellipse_container">
                <div className="ellipse pink"></div>
                <div className="ellipse orange"></div>
            </div>
            
        </div>
    </div>
    </div>
}

export default Home;