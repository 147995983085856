import React from 'react';
import { FaStar } from 'react-icons/fa';
import '../scss/_shoppage.scss';

const products = [
  {
    title: 'Tablette Motema 1.0',
    price: '250$',
    stars: 4,
    description: 'La Tablette Motema 10 est une tablette abordable avec un écran Full HD, une autonomie de 12 heures et un accès facile aux services ToonaShop',
    image: 'https://www.presidence.cd/uploads/1B3A6749%20(1).jpg'
  },
  {
    title: 'Support tablette Reglable noir',
    price: '49.99$',
    stars: 5,
    description: 'Ce support est très puissant et polyvalent ',
    image: 'https://m.media-amazon.com/images/I/51gzTzO5M1L._AC_UL320_.jpg'
  },
  {
    title: 'Support tablette en aluminium',
    price: '99.99$',
    stars: 5,
    description: 'Ce support offre des performances inégalées.',
    image: 'https://m.media-amazon.com/images/I/61deKLAUkEL._AC_UL320_.jpg'
  }
];

const ShopPage = () => {
  return (
    <div className="shop-page">
      <h1>Découvrez nos Tablettes livrées avec ToonaShop</h1>
      <div className="products-container">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.image} alt={product.title} className="product-image" />
            <h3>{product.title}</h3>
            <p className="price">{product.price}</p>
            <div className="stars">
              {Array(product.stars).fill().map((_, i) => (
                <FaStar key={i} className="star-icon" />
              ))}
            </div>
            <p className="description">{product.description}</p>
            <button className="buy-button">Acheter</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopPage;
