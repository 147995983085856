import React from 'react';
import iphone1 from '../assets/iphone1.svg'
import iphone2 from '../assets/Iphone2.svg'
import macbook from '../assets/macbook.svg'
import macbook2 from '../assets/macbook2.svg'
import useImageSlider from '../Hooks/useImageSilder';

import'../scss/_images.scss';



const Images= () => {

const images_phones=[iphone1, iphone2];
const images_pc=[macbook, macbook2];
const images_phone=useImageSlider(images_phones, 3000);
const images_computer=useImageSlider(images_pc, 3000);

  return (
    <div className="images_container">
        <div className="image">
            <img src={images_phone} alt="" />
        </div>
        <div className="image">
            <img src={images_computer} alt="" />
        </div>
    </div>
  );
};

export default Images;
