import '../scss/_aboutus.scss';

import logo from '../assets/c.png';
import Mission from './Mission';

import { FaWarehouse} from 'react-icons/fa';
import Partenaire from './partenaire';
import { useEffect } from 'react';

import scrollreveal from 'scrollreveal';


const AboutUs = () =>{

    useEffect(() =>{
        const registeranimation=() =>{
            const sr=scrollreveal({
              origin: 'bottom',
              distance: '80px',
              duration:2000,
              reset:false,
            });
            sr.reveal(
              `.about_us_container, .description_container,.mission_vision_container,.mission_vision`,
              {interval : 500}
            )
        }
    
        registeranimation();
      },[])

    const missions=[
        {
            icon: <FaWarehouse />,
            title:'Premiere Vision',
            content: 'Fournir les meilleurs produits logiciels pour permettre aux organisations de mieux gérer leurs activités et leurs opérations',
        },
        {
            icon: <FaWarehouse />,
            title:'Deuxième Vision',
            content: 'Fournir des solutions informatiques efficaces et des services de qualité pour améliorer les avantages concurrentiels de nos clients',
        },
        {
            icon: <FaWarehouse />,
            title:'Troisième Vision',
            content: 'Maintenir un environnement social durable et être une entreprise socialement responsable.',
        },
    ]


    return <>
        <div className="about_us_container">
            <div className="image_container">
                <img src={logo} alt="" />
            </div>
            <div className="ellipse_container">
                <div className="ellipse pink"></div>
                <div className="ellipse orange"></div>
            </div>
            <div className="description_container">
                <h2><span>Qui</span> sommes nous?</h2>
                <p>
                    <b>Calculus System </b>basé à Kinshasa est une entreprise 
                    spécialiste des services informatiques dédiés aux
                    entreprises depuis exactement 10 ans. Dans ce cadre 
                    d’activité, nous agissons dans les domaines des
                    applications (web et mobile), des VAS et Terminaux, de l’IT 
                    ainsi que de la cyber sécurité.
                </p>
            </div>
        </div>
        <div className="mission_vision_container">
            <h2>Notre<span> Vision</span> & <span>Mission</span></h2>
            <div className="mission_vision">
                <div className="mission">
                    {
                        missions.map((mission,index) =>(
                            <Mission 
                                icon={mission.icon} 
                                title={mission.title} 
                                content={mission.content}
                            />
                        ))
                    }
                    
                    
                </div>
                <div className="vision">
                    <div className="icon_container">
                        <FaWarehouse />
                    </div>
                    <div className="content_container">
                        <h3>Vision de l'Entreprise</h3>
                        <p>
                            Construire des meilleures communautés 
                            grâce aux solutions numériques en les 
                            rendant simples, accessibles et abordables
                        </p>
                        <p>
                            Créer un écosystème social et économique 
                            agréable pour tous
                        </p>
                    </div>
                    <div className="ellipse_container">
                        <div className="ellipse pink"></div>
                        <div className="ellipse orange"></div>
                    </div>
                </div>
            </div>
        </div>
    </>



}

export default AboutUs;