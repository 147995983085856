import '../scss/_login.scss';
import logo from '../assets/logo.png';

import React, { useState, useReducer, useEffect } from 'react';


const initialState = {
  firstName: '',
  lastName: '',
  companyName: '',
  rccm: '',
  currency: 'USD',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  errors: {}
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_ERROR':
      return { ...state, errors: { ...state.errors, [action.field]: action.error } };
    case 'CLEAR_ERRORS':
      return { ...state, errors: {} };
    default:
      return state;
  }
}

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const invalidDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
  if (!regex.test(email) || invalidDomains.some(domain => email.endsWith(domain))) {
    return 'Please Email must be a valid professional email address ';
  }
  return null;
};

const validatePhone = (phone) => {
  const regex = /^\+243\d{9}$/;
  if (!regex.test(phone)) {
    return 'Phone number must start with +243 and contain 10 digits.';
  }
  return null;
};

const validatePassword = (password) => {
  if (password.length <= 8) {
    return 'Password must be longer than 8 characters.';
  }
  return null;
};

const validateRCCM = (rccm) => {
  const regex = /^\d{9}$/;
  if (!regex.test(rccm)) {
    return 'RCCM number must be a 9-digit code.';
  }
  return null;
};

const Login = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const emailError = validateEmail(state.email);
    dispatch({ type: 'SET_ERROR', field: 'email', error: emailError });
  
    const phoneError = validatePhone(state.phone);
    dispatch({ type: 'SET_ERROR', field: 'phone', error: phoneError });
  
    const passwordError = validatePassword(state.password);
    dispatch({ type: 'SET_ERROR', field: 'password', error: passwordError });
  
    const rccmError = validateRCCM(state.rccm);
    dispatch({ type: 'SET_ERROR', field: 'rccm', error: rccmError });
  
    const confirmPasswordError = state.password !== state.confirmPassword ? 'Passwords do not match.' : null;
    dispatch({ type: 'SET_ERROR', field: 'confirmPassword', error: confirmPasswordError });
  
    const isValid = !emailError && !phoneError && !passwordError && !rccmError && !confirmPasswordError;
    setIsFormValid(isValid);
  }, [state.email, state.phone, state.password, state.rccm, state.confirmPassword]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(state.errors).length === 0) {
      try {
        const response = await fetch('https://api.example.com/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(state)
        });

        if (response.ok) {
          alert('Registration successful!');
        } else {
          alert('Registration failed. Please try again.');
        }
      } catch (error) {
        alert('An error occurred. Please try again.');
      }
    } else {
      alert('Please fix the errors in the form before submitting.');
    }
  };

  const handleChange = (field, value) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Le Meilleur logiciel du marché pour votre gestion de stock</h2>
        {/* <div className="badges">
          <div className="badge">Best Support<br />Winter 2024</div>
          <div className="badge">Leader<br />Winter 2024</div>
          <div className="badge">Easiest To Use<br />Winter 2024</div>
        </div> */}
        <blockquote className="typewriter">
        "Une révolution pour la gestion de Stock"<br />
        <span>- Sonia R, Responsable RH en entreprise</span>
      </blockquote>
      <blockquote className="typewriter">
        "TonnaShop est le meilleur outil de gestion de stock sur le marché."<br />
        <span>- Steve G, Directeur technique</span>
      </blockquote>
      <blockquote className="typewriter">
        "Système polyvalent, avec un excellent support de l'équipe ToonaShop."<br />
        <span>- Jacqui M, Chef du capital humain</span>
      </blockquote>

      </div>
      <div className="login-right">
        <h2>Bienvenue Sur ToonaShop</h2>
        <p>Commencez votre Essai Gratuit</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              value={state.firstName}
              onChange={(e) => handleChange('firstName', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              value={state.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Company Name:</label>
            <input
              type="text"
              value={state.companyName}
              onChange={(e) => handleChange('companyName', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>RCCM Number:</label>
            <input
              type="text"
              value={state.rccm}
              onChange={(e) => handleChange('rccm', e.target.value)}
            />
            {state.errors.rccm && <span>{state.errors.rccm}</span>}
          </div>
          <div className="form-group">
            <label>Currency:</label>
            <select
              value={state.currency}
              onChange={(e) => handleChange('currency', e.target.value)}
            >
              <option value="USD">USD</option>
              <option value="CDF">CDF</option>
            </select>
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={state.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
            {state.errors.email && <span>{state.errors.email}</span>}
          </div>
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="text"
              value={state.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
            {state.errors.phone && <span>{state.errors.phone}</span>}
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={state.password}
              onChange={(e) => handleChange('password', e.target.value)}
            />
            {state.errors.password && <span>{state.errors.password}</span>}
          </div>
          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={state.confirmPassword}
              onChange={(e) => handleChange('confirmPassword', e.target.value)}
            />
            {state.errors.confirmPassword && <span>{state.errors.confirmPassword}</span>}
          </div>
          <button type="submit" disabled={!isFormValid} className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
  
};

export default Login;
