import { FaCalendar } from "react-icons/fa";
import '../scss/_modalcontent.scss';
import person from '../assets/person.jpg';
import person1 from '../assets/perso1.jpg';
import person2 from '../assets/perso2.jpg';

const ModalContent = (props) => {
    const speakers = [
        {
            name: "Mr. Patrick VERTSAPEN",
            title: "Directeur de finance",
            company: "Calculus System",
            image: person
        },
        {
            name: "Ms. Jane DOE",
            title: "CEO, Tech Innovations",
            company: "Tech Innovations",
            image: person1
        },
        {
            name: "Dr. John SMITH",
            title: "CTO, Future Tech",
            company: "Future Tech",
            image: person2
        }
    ];

    return (
        <div className="modalContent">
            <h2>Les Meilleures Pratiques pour Optimiser sa gestion de stock </h2>
            <div className="date_time">
                <FaCalendar />
                <span> July 24, 2024</span>
                <span>|</span>
                <span> 12:30 PM to 1:15 PM </span>
            </div>
            <span className='span'>SPEAKERS</span>
            <div className="speakers">
                {speakers.map((speaker, index) => (
                    <div key={index} className="speaker">
                        <img src={speaker.image} alt={speaker.name} />
                        <div className="speaker_description">
                            <span>{speaker.name}</span>
                            <span>{speaker.title}</span>
                            <span>{speaker.company}</span>
                        </div>
                    </div>
                ))}
            </div>
            <button>Enregistrez-vous, c'est gratuit</button>
            {/* <button onClick={() => props.setShowWebinarModal(false)}>Close</button> */}
        </div>
    );
}

export default ModalContent;
