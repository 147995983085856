import { useEffect, useState } from "react";

const useImageSlider = (images, delay) => {


    const [index, setIndex] = useState(0);

    useEffect(()=>{

        const interval =setInterval(() =>{
            setIndex((prev) => (prev+1) % images.length)
        }, delay)

        return () => clearInterval(interval);

    },[images, delay])

    return images[index];


}

export default useImageSlider;