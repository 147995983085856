
const Mission = (props) =>{

    return <>
        <div className="mission_container">
            <div className="icon_container">
                {props.icon}
            </div>
            <div className="content_container">
                <h3>{props.title}</h3>
                <p>{props.content}</p>
            </div>
        </div>
    </>


}

export default Mission;