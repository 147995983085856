import React, { useEffect } from 'react';
import '../scss/_productpage.scss';
import { useLocation } from 'react-router-dom';

import { FaShoppingCart, FaPills, FaUtensils , FaIndustry} from 'react-icons/fa';



const produits = [
    {
      title: 'Retail',
      description: 'Optimisez votre commerce de détail avec notre solution complète de gestion des ventes, des stocks et des clients. Suivez facilement les transactions, gérez vos inventaires en temps réel et améliorez l\'expérience client grâce à des outils de CRM intégrés. Augmentez vos ventes et fidélisez vos clients avec des rapports détaillés et des analyses approfondies.',
      icon: <FaShoppingCart />,
      id: 'retail'
    },
    {
      title: 'Pharmacie et Médical',
      description: 'Assurez une gestion efficace de votre pharmacie ou établissement médical avec notre logiciel spécialisé. Gérez vos stocks de médicaments, suivez les prescriptions, et maintenez des niveaux de stock optimaux pour éviter les ruptures. Facilitez les transactions sécurisées et améliorez la satisfaction des patients avec des fonctionnalités de gestion de clients et de paiements intégrées.',
      icon: <FaPills />,
      id: 'pharmacie_medical'
    },
    {
      title: 'Restaurant',
      description: 'Transformez votre restaurant avec notre solution de gestion intégrée. Suivez les commandes en temps réel, gérez vos stocks de nourriture et de boissons, et assurez une expérience client exceptionnelle. Optimisez vos opérations avec des fonctionnalités de suivi des ventes, gestion des fournisseurs et intégration avec les systèmes de paiement pour une gestion fluide et efficace de votre établissement.',
      icon: <FaUtensils />,
      id: 'restaurant'
    },
    {
        title: 'Industrie',
        description: 'Améliorez l\'efficacité de votre entreprise industrielle avec notre solution complète de gestion. Suivez et gérez les processus de production, optimisez les stocks de matières premières et produits finis, et assurez une traçabilité complète. Notre logiciel offre des fonctionnalités avancées de gestion des fournisseurs, des clients et des paiements, ainsi que des outils de reporting et d\'analyse pour prendre des décisions informées.',
        icon: <FaIndustry />,
        id: 'industrie'
      }
      
  ];
  


const ProductPage = () => {

    const location=useLocation();

    useEffect(()=>{

        if(location.hash){
        const element=document.getElementById(location.hash.replace('#',''));
        if(element){
            const yOffset = -100; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        }
    }, [location])


    return (
        <div className="produits-section">
            <h2>Nos Produits</h2>
            <div className="produits-container">
                {produits.map((produit, index) => (
                    <div key={index} className="produit-card" id={produit.id} >
                        <div className="icon" >{produit.icon}</div>
                        <div className="content" >
                            <h3>{produit.title}</h3>
                            <p>{produit.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductPage;
