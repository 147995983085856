import React from 'react';
import Home from './Home';
import Partenaire from './partenaire';
import Fonctionnalities from './fonctionnalities';
import AvisClients from './AvisClient';
import Advantages from './Avantages';
import VisitorCounter from './VisitorCounter';
import Images from './Images';


const HomePage = () => {


  return (
    <div>
      <Home />
      <Partenaire content='Plus de 50 Entreprises nous font confiance' isPartenaire={true} />
      <Advantages />
      <Fonctionnalities />
      <Images />
      <VisitorCounter />
      <AvisClients />
      <Partenaire content='Essayez ToonaShop Gratuitement' isPartenaire={false} />
    </div>
  );
};

export default HomePage;
