/* eslint-disable jsx-a11y/anchor-is-valid */
import '../scss/_fonctionnalities.scss';

import { FaWarehouse, FaUser, FaUserCheck, FaUsers, FaShoppingCart} from 'react-icons/fa';

// const functionnalities =[
//     {
//         icon:<FaWarehouse />,
//         title: 'Gestion de site de ventes',
//         content:'Intégration facile avec vos plateformes de vente',
//     }
// ]


const Fonctionnalities = () => {

    return <div className="fonctionnalities">
        <h2>Découvrez les fonctionnalités de ToonaSHOP...</h2>
        <div className="all_functionnalities">
            <div className="left">
                <div className="left_functionnality">
                    <FaWarehouse className='icon' />
                    <div className="content">
                        <h3>
                            Gestion de sites de Ventes
                        </h3>
                        <p>
                            Intégration facile avec vos plateformes de vente
                        </p>
                    </div>
                </div>
                <div className="left_functionnality">
                    <FaUser className='icon'  />
                    <div className="content">
                        <h3>
                            Gestion de Fournisseurs
                        </h3>
                        <p>
                            Ajoutez et Gérez vous Fournisseurs sans Efforts
                        </p>
                    </div>
                </div>
                <div className="left_functionnality">
                    <FaShoppingCart className='icon'  />
                    <div className="content">
                        <h3>
                            Gestion des Articles
                        </h3>
                        <p>
                            Suivi detaillé de vos produits
                        </p>
                    </div>
                </div>
                
            </div>
            <div className="right">
                <div className="left_functionnality">
                        <FaUsers className='icon'  />
                        <div className="content">
                            <h3>
                                Gestion des Utilisateurs
                            </h3>
                            <p>
                                Controlez les roles et les accès des utilisateurs
                            </p>
                        </div>
                    </div>
                    <div className="left_functionnality">
                        <FaUserCheck className='icon'  />
                        <div className="content">
                            <h3>
                                Gestion des Clients
                            </h3>
                            <p>
                                Historique et suivi de vos interractions avec vos clients
                            </p>
                        </div>
                    </div>
            </div>
        </div>

    </div>


}

export default Fonctionnalities;