/* eslint-disable jsx-a11y/anchor-is-valid */
import { BsFillMoonFill } from 'react-icons/bs';
import { FaBars, FaTimes, FaBook, FaChartLine, FaClock, FaCog, FaHandshake, FaShoppingCart, FaPills, FaUtensils, FaIndustry, FaBlog, FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import { ImSun } from 'react-icons/im';
import '../scss/_navbar.scss';
import LogoBanner from './LogoBanner';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FreeTrialModal from './Modal';
import WebinarModal from './WebinaireModal';

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClicked, setIsClicked] = useState(null);
    const [navbarScrolled, setNavbarScrolled] = useState(false);
    const sidebarRef = useRef(null);
    const location=useLocation();
    const [showFreeTrial, setShowFreeTrial] = useState(false);
    const [showWebinar, setShowWebinar] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    

    

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 100) { // 100px est un exemple, ajustez en fonction de votre besoin
                setNavbarScrolled(true);
            } else {
                setNavbarScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFreeTrial(true);
        }, 10000);


        return () => clearTimeout(timer);
    }, []);

    const handlePricingClick = () => {
        setIsOpen(false);
        setTimeout(() => props.setShowWebinarModal(true), 3000); // Show webinar modal after 3 seconds
      };
    const handleClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.toonashop.toonashopv2', '_blank', 'noopener,noreferrer');
    };

    const NavLink = ({ to, icon, text, onClick }) => (
        <Link to={to} onClick={onClick}>
            <div className="space">
                {icon}
                <a href={to}>{text}</a>
            </div>
        </Link>
    );

    const Dropdown = ({ title, links }) => (
        <li className={`dropdown ${isClicked === title ? 'active' : ''}`}>
            <a href={`#${title.toLowerCase()}`} onClick={() => setIsOpen(false)}>
                <Link to={`/${title === 'Ressources' ? 'blog': title.toLowerCase()}`}>{title}</Link>
            </a>
            <div className="dropdown-content">
                {links.map(({ to, icon, text }) => (
                    <NavLink key={to} to={to} icon={icon} text={text} onClick={() => setIsOpen(false)} />
                ))}
            </div>
        </li>
    );

    return (
        <nav className={`nav ${navbarScrolled ? 'scrolled' : ''}`}>
            <LogoBanner />
            <div className="toggle_menu" onClick={toggleSidebar}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div ref={sidebarRef} className={`link_container ${isOpen ? 'open' : ''}`}>
                <div className="links">
                    <Dropdown
                        title="Solutions"
                        links={[
                            { to: '/solutions#formation-soutien', icon: <FaBook />, text: 'Formation et Soutien' },
                            { to: '/solutions#gestion-financiere', icon: <FaChartLine />, text: 'Rapports financiers' },
                            { to: '/solutions#suivi-temps-reel', icon: <FaClock />, text: 'Suivi temps Réel' },
                            { to: '/solutions#automatisation-processus', icon: <FaCog />, text: 'Automatisation Processus' },
                            { to: '/solutions#renforcement-confiance', icon: <FaHandshake />, text: 'Relation de Confiance' }
                        ]}
                    />
                    <Dropdown
                        title="Products"
                        links={[
                            { to: '/products#retail', icon: <FaShoppingCart />, text: 'Retail' },
                            { to: '/products#pharmacie_medical', icon: <FaPills />, text: 'Pharmacie et Médical' },
                            { to: '/products#restaurant', icon: <FaUtensils />, text: 'Restaurant' },
                            { to: '/products#industrie', icon: <FaIndustry />, text: 'Industrie' }
                        ]}
                    />
                    <li className={`dropdown ${isClicked === 'Clientele' ? 'active' : ''}`}>
                        <a href="#" onClick={() => setIsOpen(false)}>
                            <Link to="/clientele">Clientele</Link>
                        </a>
                    </li>
                    <li className={`dropdown ${isClicked === 'Tarifs' ? 'active' : ''}`}>
                        <a href="#" onClick={handlePricingClick}>
                            <Link to="/pricing">Tarifs</Link>
                        </a>
                    </li>
                    <Dropdown
                        title="Ressources"
                        links={[
                            { to: '/blog', icon: <FaBlog />, text: 'Blog' },
                            { to: '/faq', icon: <FaQuestionCircle />, text: 'FAQS' },
                            { to: '/aboutus', icon: <FaInfoCircle />, text: 'A propos de nous' }
                        ]}
                    />
                    <li className={`dropdown ${isClicked === 'Shop' ? 'active' : ''}`}>
                        <a href="#" onClick={() => setIsOpen(false)}>
                            <Link to="/shoppage">Shop</Link>
                        </a>
                    </li>
                    <li className={`dropdown ${isClicked === 'Shop' ? 'active' : ''}`}>
                        <a href="#" onClick={() => setIsOpen(false)}>
                            <Link to="/login">S'enregistrer</Link>
                        </a>
                    </li>
                    {/* <li>
                        {props.currenttheme === 'light' && <ImSun className="light" onClick={props.changetheme} />}
                        {props.currenttheme === 'dark' && <BsFillMoonFill className="dark" onClick={props.changetheme} />}
                    </li> */}
                    
                </div>
                <div className="buttons">
                    <button type="button" onClick={handleClick}>
                        Essai Gratuit
                    </button>
                </div>
            </div>
            <FreeTrialModal show={showFreeTrial} handleClose={() => setShowFreeTrial(false)} />
            <WebinarModal show={showWebinar} handleClose={() => setShowWebinar(false)} />
        </nav>
    );
};

export default NavBar;
